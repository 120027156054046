.table {
    thead th {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.5px;
        padding: 1rem;
    }

    tbody td {
        padding: 1rem;
        vertical-align: middle;
    }
}

.modal-dialog-centered {
    .modal-content {
        border: 0;
        box-shadow: 0 10px 34px rgba(0, 0, 0, .1);
    }

    .modal-header {
        border-bottom: 1px solid rgba(0, 0, 0, .05);
    }

    .modal-footer {
        border-top: 1px solid rgba(0, 0, 0, .05);
    }
}