.auth-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.container {
  z-index: 2;
}

.auth-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

.auth-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(40, 167, 69, 0.30) 0%,    /* Reduced from 0.85 to 0.65 */
    rgba(32, 201, 151, 0.30) 100% 
  );
  z-index: 1;
}

.login-card {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.95);
  border: none;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.login-logo {
  height: 120px;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.login-logo:hover {
  transform: scale(1.05);
}

.logo-underline {
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    #28a745 50%,
    transparent 100%
  );
  margin: 1rem auto;
  width: 80%;
  opacity: 0.5;
}

.welcome-text {
  color: #28a745;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.login-subtitle {
  color: #6c757d;
  font-size: 0.95rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 500;
  color: #495057;
  margin-bottom: 0.5rem;
}

.input-group {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.input-group-text {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #6c757d;
  padding: 0.75rem;
}

.form-control-lg {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
}

.login-button {
  background-color: #28a745 !important;
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
}

@media (max-width: 768px) {
  .login-card {
    margin: 1rem;
  }
  
  .login-logo {
    height: 100px;
  }
}