.landing-page {
  background-color: #f8f9fa;
}

.hero-section {
  background: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.hero-card {
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.hero-card, .hero-image {
  will-change: transform, opacity;
}

/* Ensure content is visible even if JavaScript fails */
.no-js .hero-card,
.no-js .hero-image {
  opacity: 1;
  transform: none;
}

.pre-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9rem;
  color: #28a745;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
}


.center-title h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}



.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #28a745, #20c997);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-features {
  margin: 2rem 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.check-icon {
  color: #28a745;
  margin-right: 10px;
}

.hero-subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.hero-image {
  max-width: 90%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.3s ease;
}


.hero-image:hover {
  transform: translateY(-10px);
}

.discover-button {
  padding: 1rem 2rem;
  font-weight: 600;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(40, 167, 69, 0.2);
}

.discover-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(40, 167, 69, 0.3);
}


.features-section {
  padding: 5rem 0;
  background-color: #f8f9fa;
}

.features-wrapper {
  border: none;
  border-radius: 15px;
  background: white;
}

.section-title {
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 3rem;
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.feature-card:hover {
  transform: translateY(-5px);
}

.module-icon {
  font-size: 2.5rem;
  color: #28a745;
  margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .hero-section {
    padding: 4rem 0;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-image {
    max-width: 60%;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    text-align: center;
  }

  .hero-buttons {
    justify-content: center;
  }
}
.footer-section {
  background-color: #2c3e50;
  color: white;
  padding: 2rem 0 0;
}

.footer-section h4 {
  color: #fff;
  font-weight: 600;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.footer-section a:hover {
  color: #28a745;
}

.contact-list {
  margin: 0;
  padding: 0;
}

.footer-divider {
  border-color: rgba(255, 255, 255, 0.1);
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .features-section {
    padding: 3rem 0;
  }
  
  .footer-section {
    text-align: center;
  }
  
  .contact-list li a {
    justify-content: center;
  }
}

.login-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.login-button {
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 992px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-section {
    padding: 4rem 0;
  }
  
  .hero-card {
    margin-bottom: 2rem;
  }
}
