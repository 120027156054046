.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-bar {
  border-bottom: 1px solid #dddddd;
}

.content-wrapper {
  display: flex;
  flex: 1;
}
.sidebar {
  transition: all 0.3s ease;
}
.sidebar-nav .nav-link {
  color: #333;
  padding: 0.8rem 1rem;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
}

.sidebar-nav .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.hover-success:hover {
  background-color: rgba(var(--bs-success-rgb), 0.1);
  color: var(--bs-success) !important;
}

.header-bar {
  backdrop-filter: blur(8px);
}

.main-content {
  min-height: calc(100vh - 136px);
}

.footer {
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 4px rgba(0,0,0,.05);

}

.header-bar {
  border-bottom: 1px solid #e9ecef;
}

.navbar {
  box-shadow: 0 2px 4px rgba(0,0,0,.05);
}

.header-bar .navbar-brand img {
  transition: transform 0.2s ease;
}

.header-bar .navbar-brand:hover img {
  transform: scale(1.05);
}

.receipt {
  display: none;
}

@media print {
  .receipt {
    display: block;
  }
}

.split-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.product-list,
.cart {
  height: 100%;
  overflow-y: auto;
}

.splitter {
  width: 4px;
  background-color: #e9ecef;
  cursor: col-resize;
  transition: background-color 0.2s;
}

.splitter:hover,
.splitter:active {
  background-color: #6c757d;
}

.map-popup {
  min-width: 200px;
}

.map-popup .card-body {
  padding: 10px;
}

.map-popup h5 {
  margin-bottom: 10px;
  font-size: 16px;
}

.map-popup p {
  margin-bottom: 5px;
  font-size: 14px;
}

