@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.vertical-nav-wrapper {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $gray-100;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: 3px;

    &:hover {
      background: $gray-500;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.9));
    pointer-events: none;
  }
}

.vertical-nav {
  .nav-link {
    color: #495057;
    transition: all 0.3s ease;
    border-radius: 8px;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: rgba(40, 167, 69, 0.1);
      color: #28a745;
    }

    &.active {
      background-color: #28a745 !important;
      color: white !important;
      box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2);

      svg {
        color: white !important;
      }
    }

    svg {
      color: #28a745;
      transition: color 0.3s ease;
    }
  }
}